<template>
  <div class=""></div>
</template>

<script>
const axios = require("axios");

export default {
  components: {},
  data() {
    return {
      scrollPercent: null,
    };
  },
  mounted() {
    this.activateCode();
  },
  methods: {
    async activateCode() {
      const result = await axios.post("/activateCode", {
        code: this.$route.params.code,
      });
      if (result.data.result) {
        this.$root.notify("Sėkmingai aktyvavote savo paskyrą...");
      } else {
        this.$root.notify("Įvyko klaida aktyvuojant...", "error");
      }
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
